import { PyMuiIconButton } from 'components/editor/GridDndEditor/TiptapEditor/components/generalComponents';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import { CSSProperties } from 'react';

const textAlignmentButtons = [
  { attributeName: 'right', icon: <FormatAlignRightIcon /> },
  { attributeName: 'center', icon: <FormatAlignCenterIcon /> },
  { attributeName: 'left', icon: <FormatAlignLeftIcon /> },
  { attributeName: 'justify', icon: <FormatAlignJustifyIcon /> },
];

type TextAlignmentButtonsProps = {
  activeAlignment: Omit<CSSProperties['textAlign'], 'undefined'>;
  onChange: (alignment: string) => void;
};

const TextAlignmentButtons = ({ activeAlignment, onChange }: TextAlignmentButtonsProps) => {
  return textAlignmentButtons.map(({ attributeName, icon }) => (
    <PyMuiIconButton
      key={`style-${attributeName}`}
      attributeName={attributeName}
      disabled={false}
      onClick={() => {
        onChange(attributeName);
      }}
      isActive={activeAlignment === attributeName}
    >
      {icon}
    </PyMuiIconButton>
  ));
};

export default TextAlignmentButtons;
