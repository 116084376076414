import React, { useState, createContext, useEffect } from 'react';
import './margin/MarginSettings.less';
import { gridDefaultPixelDensity, gridPageMaxWidthInPixels, gridPageMinHeightInPixels } from '../../../shared/gridConfig';
import { useSocketClient } from '../../../../../providers/SocketContext';
import { Margin } from './models/Margin.model';
import { MarginSide } from './models/MarginSide.enum';
import { UndoRedoManager } from 'components/editor/UndoRedo/UndoRedoManager';
import { UndoRedoCommand } from '../../../UndoRedo/UndoRedoCommand';
import { useDocumentLockedModal } from '../../../modals/DocumentLockedModalProvider';
import { setSaveStatus, setSaveStatusBasedOnApiResponse } from '../../../grid/reduxStore/saveStatusSlice';
import { DefaultSocketResponseType, DocumentSettingsEvents } from '../../../../../services/socket/SocketEvents';
import { DocumentSaveStatus } from '../../../shared/models/DocumentSaveStatus';
import { rootStore, useAppDispatch } from '../../../grid/reduxStore/Store';
import {
  setTextDefaultStyling,
  TextDefaultStylingState,
  UpdateTextDefaultStylingPayloadType,
} from 'components/editor/grid/reduxStore/textDefaultStylingSlice';

export type DocumentSettingsType = {
  margin: Margin;
  backgroundColor: string;
  textDefaultStyles?: TextDefaultStylingState;
};

export interface DocumentSettingsContextType {
  documentSettings: DocumentSettingsType;
  updateMargins: (value: string | number, type: MarginSide) => void;
  updateBackgroundColor: (color: string) => void;
  showMargins: boolean;
  setShowMargins: (show: boolean) => void;
  updateTextDefaultStyle: ({ type, style }: UpdateTextDefaultStylingPayloadType) => void;
}

export const DocumentSettingsContext = createContext<DocumentSettingsContextType>({} as DocumentSettingsContextType);

interface DocumentSettingsProviderProps {
  children: any;
}

type DocumentSettingsUndoRedoType = {
  current: DocumentSettingsType;
  new: DocumentSettingsType;
  setterWithoutUndoRedo: SetDocumentSettingsType;
};

type TextDefaultStylesUndoRedoType = {
  current: TextDefaultStylingState;
  new: TextDefaultStylingState;
  setterWithoutUndoRedo: (textDefaultStyle: TextDefaultStylingState) => void;
};

type UseDocumentSettingsUndoRedoReturnType =
  | {
      documentSettings: DocumentSettingsUndoRedoType;
      textDefaultStyles?: never;
    }
  | {
      documentSettings?: never;
      textDefaultStyles: TextDefaultStylesUndoRedoType;
    };

const useDocumentSettingsUndoRedo = () => {
  const undoRedoManager = UndoRedoManager.getUndoRedoManager();
  return ({ documentSettings, textDefaultStyles }: UseDocumentSettingsUndoRedoReturnType) => {
    const undoCallback = async (): Promise<boolean> => {
      try {
        if (documentSettings) {
          documentSettings.setterWithoutUndoRedo(documentSettings.current);
        } else if (textDefaultStyles) {
          textDefaultStyles.setterWithoutUndoRedo(textDefaultStyles.current);
        }
      } catch (error) {
        return false;
      }
      return true;
    };
    const redoCallback = async (): Promise<boolean> => {
      try {
        if (documentSettings) {
          documentSettings.setterWithoutUndoRedo(documentSettings.new);
        } else if (textDefaultStyles) {
          textDefaultStyles.setterWithoutUndoRedo(textDefaultStyles.new);
        }
      } catch (error) {
        return false;
      }
      return true;
    };

    undoRedoManager.pushUndoRedoCommands(new UndoRedoCommand(undoCallback, redoCallback));
  };
};

type SetDocumentSettingsType = (newDocumentSettings: DocumentSettingsType) => void;
const useDocumentSettings = () => {
  const defaultMarginValue = 0;
  const { socketClient } = useSocketClient();
  const saveUndoRedoDocumentSettings = useDocumentSettingsUndoRedo();
  const { checkDocumentLockStatus } = useDocumentLockedModal();
  const dispatch = useAppDispatch();

  const [documentSettings, updateDocumentSettings] = useState<DocumentSettingsType>({
    margin: {
      top: defaultMarginValue,
      bottom: defaultMarginValue,
      left: defaultMarginValue,
      right: defaultMarginValue,
    },
    backgroundColor: '#ffffffff',
  });

  const onDocumentSettingsLoad = ({
    content: newSettings,
  }: {
    content: DocumentSettingsType & { textDefaultStyles?: TextDefaultStylingState };
  }) => {
    updateDocumentSettings({ margin: { ...newSettings.margin }, backgroundColor: newSettings.backgroundColor });

    if (newSettings.textDefaultStyles) {
      dispatch(setTextDefaultStyling(newSettings['textDefaultStyles']));
    }
  };

  useEffect(() => {
    if (!socketClient.isConnected()) return;

    socketClient.publish(DocumentSettingsEvents.DOCUMENT_SETTINGS_LOAD, '', onDocumentSettingsLoad);
  }, [socketClient.isConnected()]);

  const updateSettingsCallback = (response: DefaultSocketResponseType) => {
    dispatch(setSaveStatusBasedOnApiResponse({ status: response.status, errorCode: response.errorCode }));
    checkDocumentLockStatus(response.errorCode);
  };

  const setDocumentSettings: SetDocumentSettingsType = (newDocumentSettings) => {
    dispatch(setSaveStatus({ status: DocumentSaveStatus.SAVING }));
    setDocumentSettingsWithoutUndoRedo(newDocumentSettings);
    saveUndoRedoDocumentSettings({
      documentSettings: {
        current: documentSettings,
        new: newDocumentSettings,
        setterWithoutUndoRedo: setDocumentSettingsWithoutUndoRedo,
      },
    });
  };
  const setDocumentSettingsWithoutUndoRedo = (newDocumentSettings: DocumentSettingsType) => {
    updateDocumentSettings(newDocumentSettings);
    socketClient.publish(DocumentSettingsEvents.DOCUMENT_SETTINGS_SAVE, newDocumentSettings, updateSettingsCallback);
  };

  const updateTextDefaultStyle = ({ type, style }: UpdateTextDefaultStylingPayloadType) => {
    dispatch(setSaveStatus({ status: DocumentSaveStatus.SAVING }));
    const textDefaultStyles = rootStore.getState().textDefaultStylingReducer;
    const newTextDefaultStyles = { ...textDefaultStyles, [type]: { ...textDefaultStyles[type], ...style } };

    const textDefaultStylesSetter = (newTextDefaultStyles: TextDefaultStylingState) => {
      dispatch(setTextDefaultStyling(newTextDefaultStyles));
    };

    textDefaultStylesSetter(newTextDefaultStyles);
    saveUndoRedoDocumentSettings({
      textDefaultStyles: {
        current: textDefaultStyles,
        new: newTextDefaultStyles,
        setterWithoutUndoRedo: textDefaultStylesSetter,
      },
    });
    socketClient.publish(
      DocumentSettingsEvents.DOCUMENT_SETTINGS_SAVE,
      { textDefaultStyles: newTextDefaultStyles },
      updateSettingsCallback
    );
  };

  return { documentSettings, setDocumentSettings, updateTextDefaultStyle };
};
export const DocumentSettingsProvider: React.FC<DocumentSettingsProviderProps> = ({ children }: DocumentSettingsProviderProps) => {
  const { documentSettings, setDocumentSettings, updateTextDefaultStyle } = useDocumentSettings();
  const [showMargins, setShowMargins] = useState<boolean>(false);

  const updateMargins = (value: string | number, type: MarginSide) => {
    const valueInInches = typeof value === 'string' ? parseFloat(value) : value;
    if (valueInInches < 0) return;

    const valueInPixels = valueInInches * gridDefaultPixelDensity;
    if ((type === MarginSide.LEFT || type === MarginSide.RIGHT) && valueInPixels > gridPageMaxWidthInPixels) {
      return;
    }

    if ((type === MarginSide.TOP || type === MarginSide.BOTTOM) && valueInPixels > gridPageMinHeightInPixels) {
      return;
    }

    const newMargin: Margin = { ...documentSettings.margin, [MarginSide[type].toLowerCase()]: parseFloat(valueInPixels.toFixed(2)) };
    setDocumentSettings({ ...documentSettings, margin: newMargin });
  };

  const updateBackgroundColor = (color: string) => {
    setDocumentSettings({ ...documentSettings, backgroundColor: color });
  };

  return (
    <DocumentSettingsContext.Provider
      value={{ documentSettings, updateMargins, updateBackgroundColor, showMargins, setShowMargins, updateTextDefaultStyle }}
    >
      {children}
    </DocumentSettingsContext.Provider>
  );
};
