import { useSectionData } from '../../../Sections/useSectionContext';
import { useBlockContentChangedHandler } from '../../../hooks/UseBlockContentChangedHandler';
import { useTableManipulation } from '../../Block/Table/useTableManipulation';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useActiveEditor } from '../ActiveEditorContext';
import { SelectionContext } from '../../SelectedBlockInfoProvider';
import { Editor, EditorContent, EditorEvents, useEditor } from '@tiptap/react';
import { RowTypesIdentifier } from '../../../grid/reduxStore/table.types';
import { getSanitizedDataForTableFooter } from '../../../../../utils/getSanitizedDataForTableFooter';
import { getTipTapExtensions } from '../helpers/config';
import { selectAllVariablesItems } from '../../../grid/reduxStore/allVariableSlice';
import { useAppSelector } from '../../../grid/reduxStore/Store';
import { useUsedVariablesUpdate } from '../hooks/useUsedVariablesUpdate';
import { debounce } from '../../../../../utils/debounce';

export const TipTapRenderEditCell = ({ id, field, hasFocus }) => {
  const { sectionId } = useSectionData();
  const blockContentChangedHandler = useBlockContentChangedHandler();
  const debouncedBlockContentChangedHandler = useMemo(() => debounce(blockContentChangedHandler, 500), [blockContentChangedHandler]);

  const { getTableData } = useTableManipulation();
  const { activeEditor, setActiveEditor, setIsEditMode } = useActiveEditor();
  const { selectedBlockIdByWrapper } = useContext(SelectionContext);
  const allVariables = useAppSelector(selectAllVariablesItems);
  const { onSetUsedVariables } = useUsedVariablesUpdate(`${id}-${field}`);

  const blockId = selectedBlockIdByWrapper as string;
  const tableData = getTableData(blockId, sectionId);
  const cellValue = tableData?.rows.find((row) => row.id === id)?.[field];

  const updateTableRow = (row, html, data) => {
    if (row.id !== id) return row;
    if (row.rowType === RowTypesIdentifier.FOOTER) {
      return { ...getSanitizedDataForTableFooter(row, data.columns, html), rowType: RowTypesIdentifier.FOOTER };
    }
    return { ...row, [field]: html };
  };

  const updateEditorData = useCallback(
    (editor: Editor) => {
      const html = editor.getHTML();

      if (tableData) {
        const updatedRows = tableData.rows.map((row) => updateTableRow(row, html, tableData));

        debouncedBlockContentChangedHandler(blockId, sectionId, { ...tableData, rows: updatedRows });
      }
    },
    [blockId, sectionId, tableData, blockContentChangedHandler, field]
  );

  const editor = useEditor({
    content: cellValue ?? '',
    extensions: getTipTapExtensions({ variables: allVariables ?? [], onSetUsedVariables }),
    onUpdate: ({ editor }: EditorEvents['update']) => updateEditorData(editor),
    onBlur: ({ editor }: EditorEvents['blur']) => updateEditorData(editor),
  });

  useEffect(() => {
    if (hasFocus && editor && activeEditor !== editor) {
      setIsEditMode(true);
      setActiveEditor(editor);
    }
  }, [editor, activeEditor, setActiveEditor, hasFocus, setIsEditMode]);

  return editor ? <EditorContent editor={editor} /> : null;
};
