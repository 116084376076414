import { FormControl, Grid, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { CSSProperties } from 'react';

type SelectInputColumnProps = {
  menuItems: string[] | number[];
  isMultiple?: boolean;
  handleChange: (event: SelectChangeEvent<string[]>) => void;
  value?: string[];
  maxWidth?: string;
  width?: string;
  customSelectStyle?: CSSProperties;
};

export const SelectInputColumn = ({
  maxWidth = '160px',
  width = '100%',
  menuItems,
  handleChange,
  value = [],
  isMultiple = false,
  customSelectStyle,
}: SelectInputColumnProps) => {
  return (
    <Grid item flexGrow={1} width={width} maxWidth={maxWidth}>
      <FormControl variant="standard" size="small" fullWidth>
        <Select<string[]>
          id="drop-down-select"
          data-testid="drop-down-select"
          sx={{ ...customSelectStyle }}
          label={value.join(', ')}
          labelId={`${value}-label`}
          MenuProps={{ disableScrollLock: true }}
          multiple={isMultiple}
          onChange={handleChange}
          renderValue={(selected) => (Array.isArray(selected) ? selected.join(', ') : '')}
          value={value}
        >
          {menuItems.map((item) => (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>
  );
};
