import { EditorEvents } from '@tiptap/react';
import { Node } from '@tiptap/pm/model';

type TextStyleAttribute = 'fontSize' | 'fontFamily';
type AttributeValue = string | number;
type GetParentAttributeFunction = (parent: Node | null) => string;

interface TextStyleConfig {
  attributeName: TextStyleAttribute;
  getParentAttribute: GetParentAttributeFunction;
  parseValue?: (value: string) => AttributeValue;
}

export const createTextStyleHandler = ({ attributeName, getParentAttribute, parseValue = (value) => value }: TextStyleConfig) => {
  const getTextNodeAttribute = (node: Node): string | undefined => {
    const textStyleMark = node.marks.find((mark) => mark.type.name === 'textStyle');
    if (textStyleMark) {
      const value = textStyleMark.attrs[attributeName];
      return value;
    }
  };

  const updateSelectedAttribute = ({ editor }: EditorEvents['transaction'], setSelected: (value: string) => void) => {
    if (!editor) return;

    const { from, to } = editor.state.selection;
    const valuesInSelection: ReturnType<typeof parseValue>[] = [];

    if (editor.state.selection.empty) {
      const storedValue: string = editor.state.storedMarks?.find((mark) => mark.type.name === 'textStyle')?.attrs[attributeName];

      if (storedValue) {
        valuesInSelection.push(parseValue(storedValue));
      } else {
        const parent = editor.state.selection.$from.parent;
        const content = parent.content;
        let value: string | null = null;

        content.forEach((node) => {
          const mark = node.marks.find((mark) => mark.type.name === 'textStyle');
          if (mark) {
            value = mark.attrs[attributeName];
          }
        });

        if (value) {
          valuesInSelection.push(parseValue(value));
        } else {
          const parentValue = getParentAttribute(parent);
          valuesInSelection.push(parentValue);
        }
      }
    }

    editor.state.doc.nodesBetween(from, to, (node, _pos, parent) => {
      if (node.type.name === 'text') {
        const value = getTextNodeAttribute(node);
        if (value) {
          valuesInSelection.push(parseValue(value));
        } else {
          const parentValue = getParentAttribute(parent);
          valuesInSelection.push(parentValue);
        }
      }
    });

    const valuesSet = new Set(valuesInSelection);
    if (valuesSet.size === 1) {
      setSelected(Array.from(valuesSet)[0].toString());
    } else {
      setSelected('');
    }
  };

  return updateSelectedAttribute;
};
