import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './Store.ts';
import {
  DEFAULT_FONT_COLOR,
  DEFAULT_FONT_FAMILY,
  DEFAULT_LINE_HEIGHT,
  DEFAULT_SIZE,
  HEADING_FONT_SIZES,
} from './defaultTextDefaultValue.ts';
import { CSSProperties } from 'react';

type Writable<T> = {
  -readonly [P in keyof T]: Omit<T[P], 'undefined'>;
};

export type TextDefaultStylingType = {
  fontFamily: Omit<CSSProperties['fontFamily'], 'undefined'>;
  fontStyle: Omit<CSSProperties['fontStyle'], 'undefined'>;
  textDecoration: Omit<CSSProperties['textDecoration'], 'undefined'>;
  fontWeight: Omit<CSSProperties['fontWeight'], 'undefined'>;
  lineHeight: Omit<CSSProperties['lineHeight'], 'undefined'>;
  color: Omit<CSSProperties['color'], 'undefined'>;
  textAlign: Omit<CSSProperties['textAlign'], 'undefined'>;
  fontSize: Omit<CSSProperties['fontSize'], 'undefined'>;
} & Writable<CSSProperties>;

export enum TextDefaultSelectorsEnum {
  HEADING_1 = 'h1',
  HEADING_2 = 'h2',
  HEADING_3 = 'h3',
  HEADING_4 = 'h4',
  NORMAL = 'p',
}

export type TextDefaultTagSelectors = `${TextDefaultSelectorsEnum}`;

export type TextDefaultStylingState = Record<TextDefaultTagSelectors, TextDefaultStylingType>;

const sharedStyles: TextDefaultStylingType = {
  fontFamily: DEFAULT_FONT_FAMILY,
  fontSize: `${DEFAULT_SIZE}px`,
  fontStyle: 'normal',
  textDecoration: 'none',
  fontWeight: 'normal',
  lineHeight: DEFAULT_LINE_HEIGHT,
  color: DEFAULT_FONT_COLOR,
  textAlign: 'left',
};

export const tiptapTextDefaultStyle: TextDefaultStylingState = {
  [TextDefaultSelectorsEnum.NORMAL]: {
    ...sharedStyles,
  },
  [TextDefaultSelectorsEnum.HEADING_1]: {
    ...sharedStyles,
    fontWeight: 'bold',
    fontSize: `${HEADING_FONT_SIZES[1]}px`,
  },
  [TextDefaultSelectorsEnum.HEADING_2]: {
    ...sharedStyles,
    fontWeight: 'bold',
    fontSize: `${HEADING_FONT_SIZES[2]}px`,
  },
  [TextDefaultSelectorsEnum.HEADING_3]: {
    ...sharedStyles,
    fontSize: `${HEADING_FONT_SIZES[3]}px`,
  },
  [TextDefaultSelectorsEnum.HEADING_4]: {
    ...sharedStyles,
    fontSize: `${HEADING_FONT_SIZES[4]}px`,
  },
};

export type UpdateTextDefaultStylingPayloadType = { type: TextDefaultTagSelectors; style: Partial<TextDefaultStylingType> };

const textDefaultStylingSlice = createSlice({
  name: 'editor-text-default-styling',
  initialState: tiptapTextDefaultStyle,
  reducers: {
    setTextDefaultStyling: (state, action: PayloadAction<TextDefaultStylingState>) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { setTextDefaultStyling } = textDefaultStylingSlice.actions;
export const textDefaultStylingReducer = textDefaultStylingSlice.reducer;
export const selectAllTextDefaultStyles = (state: RootState) => state.textDefaultStylingReducer;
