export const DEFAULT_FONT_FAMILY = 'Plus Jakarta Sans';
export const DEFAULT_LINE_HEIGHT = 'normal';
export const DEFAULT_SIZE = '12';
export const DEFAULT_FONT_COLOR = '#212121FF';

interface FontSizeOption {
  name: string;
  value: string;
}

export const DEFAULT_FONT_SIZES: FontSizeOption[] = [
  { name: '8', value: '8px' },
  { name: '9', value: '9px' },
  { name: '10', value: '10px' },
  { name: DEFAULT_SIZE, value: `${DEFAULT_SIZE}px` },
  { name: '14', value: '14px' },
  { name: '16', value: '16px' },
  { name: '18', value: '18px' },
  { name: '24', value: '24px' },
  { name: '30', value: '30px' },
  { name: '32', value: '32px' },
  { name: '42', value: '42px' },
];

interface LineHeightOption {
  name: string;
  level: string;
}

export const DEFAULT_LINE_HEIGHT_OPTIONS: LineHeightOption[] = [
  { name: 'Default', level: DEFAULT_LINE_HEIGHT },
  { name: 'Single', level: '1' },
  { name: '1.15', level: '1.15' },
  { name: '1.5', level: '1.5' },
  { name: 'Double', level: '2' },
];

export const HEADING_FONT_SIZES: Record<number, string> = {
  1: '42',
  2: '32',
  3: '24',
  4: '18',
} as const;

interface FontFamilyOption {
  name: string;
}

export const DEFAULT_FONT_FAMILY_OPTIONS: FontFamilyOption[] = [
  { name: DEFAULT_FONT_FAMILY },
  { name: 'Arial' },
  { name: 'Georgia' },
  { name: 'Roboto' },
  { name: 'Times New Roman' },
  { name: 'Work Sans' },
];
