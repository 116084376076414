import { PyMuiIconButton } from 'components/editor/GridDndEditor/TiptapEditor/components/generalComponents';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlined from '@mui/icons-material/FormatUnderlined';
import { TextDefaultStylingType } from '../../../grid/reduxStore/textDefaultStylingSlice.ts';

type AttributeNameType = keyof TextDefaultStylingType;

interface TextStyle {
  attributeName: AttributeNameType;
  activeValue: string;
  inactiveValue: string;
  icon: JSX.Element;
}

interface TextStyleButtonsProps {
  textDefaultStyles: TextDefaultStylingType;
  onChange: (attributeName: AttributeNameType, value: string) => void;
}

const textStyleButtons: TextStyle[] = [
  { attributeName: 'fontWeight', activeValue: 'bold', inactiveValue: 'normal', icon: <FormatBoldIcon /> },
  { attributeName: 'fontStyle', activeValue: 'italic', inactiveValue: 'normal', icon: <FormatItalicIcon /> },
  { attributeName: 'textDecoration', activeValue: 'underline', inactiveValue: 'none', icon: <FormatUnderlined /> },
];

const TextStyleButtons = ({ textDefaultStyles, onChange }: TextStyleButtonsProps) => {
  return textStyleButtons.map(({ attributeName, activeValue, inactiveValue, icon }) => {
    const isActive = textDefaultStyles[attributeName] === activeValue;
    return (
      <PyMuiIconButton
        key={`style-${attributeName}`}
        attributeName={attributeName}
        disabled={false}
        isActive={isActive}
        onClick={() => onChange(attributeName, isActive ? inactiveValue : activeValue)}
      >
        {icon}
      </PyMuiIconButton>
    );
  });
};

export default TextStyleButtons;
